import React, { Component } from 'react';
import { Link } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import './AddressCard.scss'
import { Modal } from 'react-bootstrap';
import ContactFormPage from '../forms/contact';
const Addresscard =(props) => {
    const addressCard = props.addressCard;
    const [show, setShow] = React.useState(false);
    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' className="total-block">
                <div className='address-card' id={addressCard.keyId}>
                    <h3>{addressCard.addressTitle}</h3>
                    <div className="address-card-block">
                        <address className="address-left">
                            {addressCard.address}
                        </address>
                        <div className="address-right">
                            {addressCard?.phoneNumber && <Link href={`tel:${addressCard.phoneNumber}`} className='phone_number'>{addressCard.phoneNumber} </Link>}                            
                            <a href="javascript:;" onClick={() => setShow(true)} className="cursor-pointer"> <i className='icon-mail'></i>{addressCard.emailAddress}</a>
                            <a href="javascript:;" onClick={() => setShow(true)} className="cursor-pointer"> <i className='icon-mail'></i>{addressCard.emailAddress2}</a>
                            {/* <Link href={`mailto:${addressCard.emailAddress}`} className='email_text'>{addressCard.emailAddress} </Link> */}
                            {/* <Link href={`mailto:${addressCard.emailAddress2}`} className='email_text'>{addressCard.emailAddress2}</Link> */}
                        </div>
                    </div>
                    <div className="btn-row">
                        <a href={`tel:${addressCard.phoneNumber}`} className="btn btn-outline d-md-none"> {addressCard.officeText} </a>
                        <Link to={addressCard.officeLink} className="btn btn-outline"> 
                            <span className='d-md-none'>{addressCard.urlText}</span> <span className='d-md-block  d-none'>{addressCard.urlText2}</span> 
                        </Link>
                    </div>
                </div>
            </ScrollAnimation>
            <Modal className="form-popup" onHide={() => setShow(false)} show={show} contentClassName="p-0" size="sm">
                <Modal.Body className="p-5">
                    <button onClick={() => setShow(false)} type="button" className="close-modal">
                        <i className="icon-close-modal"></i>
                    </button> 
                    <div className="form-block career-form">
                        <ContactFormPage email={addressCard.emailAddress}/>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default Addresscard;
