import React, { useRef,useState,useEffect } from 'react';
import { graphql, Link, useStaticQuery } from "gatsby";
import { Col, Container, Row, Form, Button, Modal, Card, Breadcrumb } from 'react-bootstrap';
import { isIOS } from 'react-device-detect';
import AddressCard from '../AddressCard/AddressCard';
import './Footer.scss' 
import { GetURL, validateEmail } from '../utils';
import HTMLReactParser from 'html-react-parser';
import EventsSubscription from '../forms/events-subscribe';
import NewsSubscription from '../forms/news-subscribe';
import classNames from 'classnames';
import { postFormData } from '../forms/api/Api';
import * as qs from "query-string";
import loadable from "@loadable/component";
import starberryLogo from "../../images/starberry-logo.png";

const PopularSearch = loadable(() => import("./PopularSearch"));
const PopularSearchDynamic = loadable(() => import("./PopularSearchForResults"));
const PopularSearchDynamicForDetailpage = loadable(() => import("./PopularSearchForDetails"));
const CookieConsent = loadable(() => import("../CookieConsent/CookieConsent"));
import DynamicBreadcrumb from '../Breadcrumb/DynamicBreadcrumb';
import PropertyDetailsBreadcrumb from '../Breadcrumb/PropertyDetailsBreadcrumb';

const Footer = (props) => {
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        if (isIOS) {
          setRenderComponent(true)
        }else {
        window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })}
    }, [])
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                siteConfig {
                    Facebook_Link
                    Instagram_Link
                    Linkedin_Link
                    Twitter_Link
                    Youtube_Link
                    Footer_Links {
                        Label
                        Link {
                            id
                        }
                    }
                    Footer_Content
                    Footer_Images {
                        url
                        alternativeText
                    }
                    Popular_About
                    Popular_Buy_Sell
                    Popular_Contact
                    Popular_Rent
                    Popular_Static
                }
                offices(where: { Publish: true}, sort: "Sort") {
                    _id
                    Title
                    Url
                    Phone
                    Email
                    Address
                }
                globalModule {
                    News_Subscribe_Form {
                        Title
                        Description
                        Card_Image {
                            alternativeText
                            url
                        }
                    }
                    Events_Subscribe_Form {
                        Title
                        Description
                        Card_Image {
                            alternativeText
                            url
                        }
                    }
                }
            }
        }
    `);
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [nameErr, setNameErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [news, setNews] = useState(false);
    const [events, setEvents] = useState(false);
    const [eventsForm, setEventsForm] = useState(false);
    const [newsForm, setNewsForm] = useState(false);

    const showMenu = () => {
        setShow(!show);
    };
    const isValid = () => {
        if (name === "" && email === "") {
            setNameErr("Name is required");
            setEmailErr("Valid Email is required");
            return false;

        } else if (name.length == 0) {
            setNameErr("Name is required");
            return false;
        } else if (validateEmail(email) == false) {
            setEmailErr("Valid Email is required");
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isValid()) {
            let formData = new FormData();
            let formvalues = {
                name: name,
                email: email,
                formname: "Newsletter Subscription",
                form_type: "newsletter_subscription",
                email_temp_user: "events_user",
                email_temp_admin: "events_admin",
                email_subject_user: "Newsletter Subscription",
                email_subject_admin: "Newsletter Subscription",
                email_server_func: "contact",
                event_tracking: "newsletter_subscription",
                page_url: "/"
            }
            // tracking event
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'formSubmit',
                'formType': 'form-' + formvalues.event_tracking,
                'formId': 'form-' + formvalues.event_tracking,
                'formName': formvalues.formname,
                'formLabel': formvalues.formname
            });
            formData.append('data', JSON.stringify(formvalues));
            postFormData(formData).then(async apiRes => {
                // lets send mail
                await window.fetch(`/api/form`, {
                    method: `POST`,
                    mode: "no-cors",
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: qs.stringify(formvalues),
                })
                setEmail("");
                setName("")
            });
        }
    }
    return (
        <section className='footer-wrapper'>
           {props?.showBreadcrumb && <div className='pre-footer'>
                <Container>
                    <Row>
                        <div className='breadcrumb-wrap'>
                        {props.isPropDetailsPage ? 
                            <PropertyDetailsBreadcrumb searchType={props?.searchtype} title={props?.title} /> :
                            <DynamicBreadcrumb label={props?.currentPage} /> 
                        }
                        </div>
                    </Row>
                </Container>
            </div> }
            <footer className='footer'>
                {renderComponent &&
                <CookieConsent /> }
                <Container>
                    <Row>
                        {data?.glstrapi?.offices?.map((off, i) => (
                            <Col md="6" xl="4" key={`key${i + 1}`}>
                                <AddressCard
                                    key={`key${i + 1}`}
                                    addressCard={{
                                        addressTitle: off.Title,
                                        address: off.Address,
                                        phoneNumber: off.Phone?.includes("@tlclondon") ? "" : off.Phone,
                                        emailAddress: off.Email,
                                        emailAddress2: off.Phone?.includes("@tlclondon") ? off.Phone : "",
                                        officeLink: "/contact/office/" + off.Url + "/",
                                        officeText: 'call office',
                                        urlText: 'visit page',
                                        urlText2: 'visit office page',
                                    }}
                                />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col>
                            <div className={show ? "footer-links" : "hide-link footer-links"}>
                                <button type='button' className='popular-link' onClick={showMenu}>popular searches <i className={classNames({ 'icon-link-plus': !show, 'icon-link-minus': show })}></i></button>
                                {show && renderComponent && <React.Fragment>
                                    {props.popularSearch && <PopularSearch
                                        data={data.glstrapi.siteConfig[props.popularSearch]}
                                    />}
                                </React.Fragment>}
                                {(props.popularSearchForResults && renderComponent && show) &&
                                    <PopularSearchDynamic
                                        {...props}
                                    />
                                }
                                {(props.popularSearchDetails && renderComponent && show) &&
                                    <PopularSearchDynamicForDetailpage
                                        {...props}
                                    />
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className='divider'>
                        <Col lg="6">
                            <div className="footer-block">
                                <h3>Mailing List</h3>
                                <p className='d-md-none'>From time to time we like to let subscribers know what we have been up to. If you are interested in hearing from us, please enter your email address below.</p>
                                <p className='d-none d-md-block'>From time to time we like to let subscribers know what we have been up to.</p>
                                { renderComponent && (
                                    <Form className="form-block" onSubmit={handleSubmit} onValidate>
                                        <div className="checkbox-row">
                                            <Form.Group controlId="property-news">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={news}
                                                    onChange={() => setNews(!news)}
                                                    label="Property News"
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="local-news">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={events}
                                                    onChange={() => setEvents(!events)}
                                                    label="Local News & Events"
                                                />
                                            </Form.Group>
                                        </div>
                                        <Form.Group className="email-input">
                                            <div>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Name"
                                                    value={name}
                                                    onChange={(e) => {
                                                        setNameErr("");
                                                        setName(e.target.value)
                                                    }}
                                                // required
                                                />
                                                {nameErr != "" && <Form.Control.Feedback type="invalid" className="d-block">
                                                    {nameErr}
                                                </Form.Control.Feedback>}
                                            </div>
                                            <div>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email Address"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmailErr("");
                                                        setEmail(e.target.value)
                                                    }}
                                                // required
                                                />
                                                {emailErr != "" && <Form.Control.Feedback type="invalid" className="d-block">
                                                    {emailErr}
                                                </Form.Control.Feedback>}
                                            </div>
                                            <div>
                                                <Button
                                                    variant="outline"
                                                    type="submit"
                                                >subscribe</Button>
                                            </div>
                                        </Form.Group>
                                        <p>By clicking Subscribe, you agree to our <Link to="/terms-and-conditions/">Terms and Conditions</Link> and <Link to="/privacy-policy/">Privacy Policy</Link>.</p>
                                    </Form>
                                )}
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="footer-right">
                                <div className='social-icon'>
                                    <a href={data?.glstrapi?.siteConfig?.Facebook_Link}>
                                        <i className='icon-fb'></i>
                                    </a>
                                    <a href={data?.glstrapi?.siteConfig?.Twitter_Link}>
                                        <i className='icon-twitter'></i>
                                    </a>
                                    <a href={data?.glstrapi?.siteConfig?.Instagram_Link}>
                                        <i className='icon-insta'></i>
                                    </a>
                                    <a href={data?.glstrapi?.siteConfig?.Youtube_Link}>
                                        <i className='icon-youtube'></i>
                                    </a>
                                    <a href={data?.glstrapi?.siteConfig?.Linkedin_Link}>
                                        <i className='icon-linkedin'></i>
                                    </a>
                                </div>
                                <div className="footer-content">
                                    <p className='d-block'>
                                        {data?.glstrapi?.siteConfig?.Footer_Links.map((each, i) =>
                                            <React.Fragment key={i}>
                                                {GetURL(each?.Link?.id)?.includes("http") ?
                                                    <a target="_blank" href={GetURL(each?.Link?.id)}>{each.Label}</a> : <Link to={GetURL(each?.Link?.id)}>{each.Label}</Link>
                                                }
                                                /
                                            </React.Fragment>
                                        )}

                                        <a href="javascript:void(0);" id="open_preferences_center" className="">&nbsp;Update Cookies Preferences</a>
                                    </p>
                                    {data?.glstrapi?.siteConfig?.Footer_Content && <React.Fragment>{HTMLReactParser(data?.glstrapi?.siteConfig?.Footer_Content)}</React.Fragment>}
                                    <p>Site by <img src={starberryLogo} width={20} height={20} loading="lazy" /><Link to="https://starberry.tv/" target="_blank"> Starberry</Link></p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                    { renderComponent && (
                        <>
                            {data?.glstrapi?.siteConfig?.Footer_Images.map((img, i) =>
                                <Col lg={2} xs={4} key={i} className="my-5">
                                    <img src={img?.url} alt={img?.alternativeText ? img?.alternativeText : "Brand Logo"} loading="lazy" width="100%" />
                                </Col>
                            )} 
                        </>)
                    }
                    </Row>
                    
                </Container>
                <Modal className="form-popup" onHide={() => setEventsForm(false)} show={eventsForm} contentClassName="p-0" size="sm">
                    <Card className="border-0">
                        <Card.Img variant="top" src={data?.glstrapi?.globalModule?.Events_Subscribe_Form?.Card_Image?.url} />
                        <Card.Header className="px-5 pt-4 border-0 bg-transparent position-relative">
                            <Card.Title>{data?.glstrapi?.globalModule?.Events_Subscribe_Form?.Title}</Card.Title>
                            <button onClick={() => setEventsForm(false)} type="button" className="close-modal">
                                <i className="icon-close-modal"></i>
                            </button>
                        </Card.Header>
                        <Card.Body className="px-5">
                            <EventsSubscription
                                email={data?.glstrapi?.globalModule?.Events_Subscribe_Form?.To_Email}
                                bcc={data?.glstrapi?.globalModule?.Events_Subscribe_Form?.To_Bcc}
                            />
                        </Card.Body>
                    </Card>
                </Modal>
                <Modal className="form-popup" onHide={() => setNewsForm(false)} show={newsForm} contentClassName="p-0" size="sm">
                    <Card className="border-0">
                        <Card.Img variant="top" src={data?.glstrapi?.globalModule?.News_Subscribe_Form?.Card_Image?.url} />
                        <Card.Header className="px-5 pt-4 border-0 bg-transparent position-relative">
                            <Card.Title>{data?.glstrapi?.globalModule?.News_Subscribe_Form?.Title}</Card.Title>
                            <button onClick={() => setNewsForm(false)} type="button" className="close-modal">
                                <i className="icon-close-modal"></i>
                            </button>
                        </Card.Header>
                        <Card.Body className="px-5">
                        { renderComponent &&
                            <NewsSubscription
                                email={data?.glstrapi?.globalModule?.News_Subscribe_Form?.To_Email}
                                bcc={data?.glstrapi?.globalModule?.News_Subscribe_Form?.To_Bcc}
                            />
                        }
                        </Card.Body>
                    </Card>
                </Modal>
            </footer>
        </section>
    );
}
export default Footer;
